#resetpass-container {
    font-family: 'Roboto', sans-serif;
    margin-top: 150px;
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
    place-items: center; /* Shorthand for align-items: center; justify-content: center; */
}

#resetpass-box {
    /* width: 300px; */
    border: 1px solid rgb(218, 218, 218);
    border-radius: 10px;
    padding: 50px;
    text-align: center;
}

@media (max-width: 768px) {
    #resetpass-container {
        margin: 0 20px;
        margin-top: 100px;
    }
}