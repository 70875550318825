#brand {
    height: 150px;
    transition: 0.25s;
    border: 0.2px solid rgb(190, 189, 189);
    border-radius: 8px;
    background-color: white;
    margin-top: 10px;
}

#brand a {
    color: black;
    text-align: center;
    text-decoration: none;
}

#brand:hover {
    transition: 0.25s;
    transform: scale(1.03);
}

#brand img {
    height: 100%;
    width: 100%;
    /* padding-left: 30px;
    padding-right: 30px; */
    padding: 30px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

@media (max-width: 768px) {
    #brand img {
        padding-left: 0px;
        padding-right: 0px;
    }
}