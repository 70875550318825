#home-category {
    height: 200px;
    transition: 0.25s;
    border: 0.2px solid rgb(190, 189, 189);
    border-radius: 8px;
    background-color: white;
    margin-top: 10px;
}

#home-category a{
    color: black;
    text-align: center;
    text-decoration: none;
}

#home-category:hover {
    transition: 0.25s;
    transform: scale(1.03);
}

#home-category img {
    height: 80%;
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

/* FOR MOBILE */
@media (max-width: 767px) {
    #home-category {
        height: 180px !important;
    }    
}

/* SMALL TABLETS */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    #home-category {
        height: 200px;
    }
}

/* BIGGER TABLETS (and small laptops)*/
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
    #home-category {
        /* height: 200px; */
    }
}