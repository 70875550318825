#cart-btn {
    color: white;
    background-color: rgb(66, 205, 66);
    border-radius: 10px;
    font-weight: 100;
    font-size: 15px;
    padding: 6px;
    width: 100%;
    text-align: center;
}

#cart-btn:hover {
    background-color: yellow !important;
    border: 1px solid rgb(219, 219, 219);
    color: black !important;
    transition: 0s;
}

.item-div {
    min-width: 200px;
    max-width: 200px;
    transition: 0.25s;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure space is distributed between elements */
    height: 100%; /* Make sure the item-div takes full height */
    box-sizing: border-box; /* Ensure padding is included in the height */
}

.item-div:hover {
    border: 0.2px solid rgb(229, 228, 228);
    border-radius: 10px;
    transition: 0.25s;
    transform: scale(1.02);
    box-shadow: 10px 10px 15px rgba(185, 184, 184, 0.3);
}

.item {
  scroll-snap-align: center;
  min-width: 100%;
  height: 150px;
  border-radius: 8px;
  object-fit: contain; /* Ensure images don't stretch */
}

.item-div .item-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: black;
    font-weight: 100;
    margin-top: 15px;
}

.price-container {
    display: flex;
    align-items: center;
    gap: 10px; /* Adjust the gap between prices as needed */
}

.original-price {
    text-decoration: line-through;
    color: red;
    font-weight: 100;
    margin-right: 5px;
}

.discounted-price {
    font-weight: bold;
}

.item-div-content {
    flex-grow: 1; /* Allow this div to grow and take available space */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
}

.item-div-buttons {
    margin-top: 10px; /* Space between content and buttons */
}
