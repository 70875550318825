#orderhistory-table {
    margin: 0 20%;
    margin-top: 2.5%;
    margin-bottom: 390px;
}

#orderhistory-table .orderhistory-th {
    font-size: 25px;
    margin-left: 10px !important;
}

#orderhistory-table td {
    padding: 15px;
    font-size: 20px;
}

@media (max-width: 768px) {
    #orderhistory-table {
        max-width: 100%;
        margin: 0 auto;
    }
    #orderhistory-table td {
        padding: 5px;
    }
}

@media only screen and (min-width: 800px) and (max-width: 1366px){
    #orderhistory-table {
        margin: 0 5% !important;
    }  
}