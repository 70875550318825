.test2-nav {
    display: flex;
    background-color: green;
    padding: 3px 15px 1px;
    width: 100%;
    margin-bottom: 2.5%;
}

.test2-nav .left-side,
.test2-nav .middle-side,
.test2-nav .right-side {
    /* margin: 0 10px 0; */
}

/* LEFT */

.test2-nav .left-side .pharmacy-div {
    display: flex;
    align-items: center;
    max-width: 125px;
    max-height: 125px;
}

.test2-nav .left-side .pharmacy-div:hover {
    cursor: pointer;
}


.test2-nav .left-side .pharmacy-div img {
    object-fit: contain;
    max-width: 100px;
    max-height: 100px;
}

.test2-nav .left-side {
    display: flex;
    align-items: center;
    /* flex: 1; */
}

.test2-nav .left-side .delivery-div {
    margin-left: 20px;
}

.test2-nav .left-side .delivery-div select {
    background-color: green;
    color: white;
}

.test2-nav .left-side .delivery-div select:hover {
    cursor: pointer;
}

.test2-nav .left-side .delivery-div span {
    color: white;
}

/* MIDDLE */

.test2-nav .middle-side,
.mobile2-nav {
    background-color: green;
    padding: 10px;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.search-bar {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
}
.search-input {
    display: block;
    position: static;
    width: 100%;
    padding: 0;
    height: 100%;
}
.search-input input {
    border-radius: 8px;
    border: 0;
    width: 99%;
    height: 99%;
    border: 1px solid #ccc;
    /* padding-left: 10px; */
    padding: 10px;
    outline: none;
}
.button-div {
    position: absolute;
    left: 100%;
    transform: translateX(-100%);
    height: 100%;
}
.button-div button {
    border: 0;
    border-radius: 8px;
    background-color: #2cd451;
    height: 100%;
    padding: 0 15px 0;
    cursor: pointer;
}
.button-div button:hover {
    background-color: yellow;
}
.button-div button i:hover {
    color: black !important;
}

.test2-nav .middle-side ul {
    position: relative;
    max-width: 100%;
}

/* .test2-nav .middle-side input {
    height: 75%;
    border: 0px;
    width: 90%;
    padding-left: 10px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
} */

#specs {
    position: absolute;
    top: calc(100% + 5px); /* Position below the input */
    left: 0;
    width: 100%; /* Match the width of the input */
    list-style-type: none;
    margin: 0;
    padding: 0;
    z-index: 1; /* Ensure it appears above other elements */
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
}

#specs li {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd; /* Add divider between results */
}

#specs li:last-child {
    border-bottom: none; /* Remove border from the last item */
}

#ulsearch-list li:hover,
#specs li:hover {
    background-color: rgb(238, 233, 233) !important;
    cursor: pointer;
}

/* .test2-nav .middle-side button {
    font-size: 13px;
    text-align: center;
    height: 76%;
    width: 5%;
    background-color: #2cd451;
    color: black;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 0;
} */

/* RIGHT */

.test2-nav .right-side {
    display: flex;
    align-items: center;
}

.test2-nav .right-side .cart-wish-div {
    display: flex;
    align-items: center;
}

.test2-nav .right-side .signin-div {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 0 8px 0;
}

.test2-nav .right-side .signin-div:hover,
.test2-nav .right-side .returns-div:hover,
.test2-nav .right-side .cart-div:hover{
    border: 0.1px solid white;
    cursor: pointer;
}

.test2-nav .right-side .signin-div span {
    color: white;
}

.test2-nav .right-side .returns-div,
.test2-nav .right-side .cart-div {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 0 8px 0;
}

.test2-nav .right-side .returns-div span,
.test2-nav .right-side .cart-div span {
    color: white;
}

/* MOBILE INPUT */

.mobile2-nav {
    display: none;
}

.mobile3-nav {
    display: none;
}

@media (max-width: 768px) {
    .mobile2-nav {
        height: 7vh;
    }
}

@media (max-width: 600px) {
    .test2-nav {
        /* padding-top: 10px; */
        margin-bottom: 0;
    }
    .test2-nav .left-side .pharmacy-div {
        max-width: 100px;
        max-height: 100px;
    }
    .test2-nav .middle-side {
       display: none;
    }
    .test2-nav .right-side {
        margin-left: auto;
    }
    .test2-nav .left-side .delivery-div,
    .test2-nav .right-side .right-select-div,
    .test2-nav .right-side .returns-div{
        display: none;
    }

    /* .mobile2-nav {
        position: relative; 
        width: 100%;
        padding: 10px;
        background-color: green;
        display: flex;
        align-items: center;
        box-sizing: border-box;
    }
    .mobile2-nav input {
        flex: 1; 
        padding: 10px;
        padding-right: 60px; 
        border: 1px solid #ccc;
        border-radius: 8px;
        font-size: 16px;
        box-sizing: border-box;
    }
    .mobile2-nav button {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #2cd451;
        border: none;
        color: white;
        font-size: 16px;
        cursor: pointer;
        padding: 0 15px;
        border-radius: 8px;
        height: calc(100% - 20px);
        box-sizing: border-box;
    } */

    .mobile2-nav {
        display: block;
    }

    .mobile3-nav {
        display: flex;
        background-color: white;
        padding: 10px 10px 10px;
        margin-bottom: 3%;
    }
}
  
/* SMALL TABLETS */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    /* .mobile2-nav {
        display: flex;
        background-color: black;
        padding: 10px 10px 10px;
        height: 6vh;
    } */
}
  
/* BIGGER TABLETS (and small laptops) */
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
    
}