#footer-muibox {
    min-height: 50vh !important;
}

#searchresults-h4 {
    color: green;
    text-align: center;
    margin-bottom: 4%;
    /* border-bottom: 0.1px solid rgb(182, 178, 178);
    padding-bottom: 20px; */
}

#searchpage-container {
    margin: 7% 15% 0;
}

#big-search-container {
    margin-bottom: 75px;
    display: flex;
    align-items: flex-start; /* Align items at the start to prevent stretching */
}

#big-search-container #listAside {
    border: 0.2px solid rgb(215, 213, 213);
    border-radius: 10px;
    width: 300px;
    max-width: 300px;
    padding: 10px;
    flex-shrink: 0; /* Prevent listAside from shrinking */
    align-self: flex-start; /* Align listAside at the start */
}

#listAside li {
    margin-bottom: 5px;
}

#listAside li a,p {
    text-decoration: none;
    color: black;
}

#listAside li a:hover {
    color: lightblue;
}

#big-search-container #productsAside {
    flex: 1; /* Adjust this to take remaining space while listAside takes its own height */
    margin-left: 20px;
}

#big-search-container #productsAside:dir(rtl) {
    margin-left: 0;
    margin-right: 20px;
}

.search-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 0px !important;
}

#search-pagination {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

/* MOBILE */
@media (max-width: 767px) {
    #searchresults-h4 {
        margin-bottom: 10%;
    }
    #searchpage-container {
        /* width: 100% !important; */
        margin-left: 2.5%;
        margin-right: 2.5%;
    }
    #big-search-container{
        grid-template-columns: 1fr;
    }
    #big-search-container #listAside {
        display: none !important;
    }
    #big-search-container #productsAside {
        width: 100% !important;
        margin: 0 !important;
    }
    .search-container {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 0px;
        width: 100%;
        overflow-y: hidden;
        max-height: 100%;
    }
}

/* SMALL TABLETS */
@media only screen and (min-width: 768px) and (max-width: 1023px){
    #big-search-container #listAside {
        width: 200px;
        max-width: 200px;
    }
    #big-search-container #listAside {
        display: block;
    }
    #searchpage-container {
        margin-left: 1%;
        margin-right: 1%;
    }
    .search-container {
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 5px;
        overflow-y: hidden;
        max-height: 100%;
    }
    /* #searchresults-h2 {
        margin-left: 5% !important;
        margin-right: 5% !important;
    } */
}

/* BIGGER TABLETS (and small laptops)*/
@media only screen and (min-width: 1024px) and (max-width: 1439px){
    #big-search-container #listAside {
        width: 240px;
        max-width: 240px;
    }
    #searchpage-container {
        margin-left: 2.5%;
        margin-right: 2.5%;
    }
    .search-container {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 0px;
        overflow-y: hidden;
        max-height: 100%;
    }
}