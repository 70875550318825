.bigcart-container {
  margin: 0 20%;
  margin-top: 2.5%;
}

.cart-container {
  border: 1px solid rgb(225, 225, 225);
  border-radius: 10px;
  padding: 10px;
}

.cartproduct-row {
  padding: 10px;
  border-bottom: 1px solid rgb(225, 224, 224);
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Enable wrapping for flex items */
}

.cartproduct-image {
  flex: 0 0 10%;
}

.cartproduct-image img {
  height: 100px;
  width: 100px;
}

.cartproduct-name {
  flex: 0 0 60%;
  overflow: hidden;
  padding: 0 10px;
  margin-left: 10px;
}

.cartproduct-name a {
  text-decoration: none;
  color: inherit;
}

.cartproduct-name .product-brand {
  font-weight: bold;
}

.cartproduct-name h6 {
  margin: 5px 0;
  white-space: normal; /* Ensure text wraps */
  word-wrap: break-word; /* Break long words if needed */
}

.cartpurchase-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px; /* Space between elements */
}

.cartpurchase-info .countInput {
  width: 40px;
  text-align: center;
  border: 1px solid rgb(212, 211, 211);
  margin: 0 5px;
}

.cartpurchase-info .price {
  width: 60px; /* Fixed width to align prices */
  text-align: center;
}

.cartpurchase-info .MuiIconButton-root {
  padding: 5px; /* Adjust padding for buttons if needed */
}

.shipment-totals {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end; /* Aligns content to the right side */
  gap: 20px; /* Adds space between .shipment-fees and .shipment-prices */
  width: 100%; /* Ensures full width for proper alignment */
}

.shipment-totals .shipment-fees,
.shipment-totals .shipment-prices {
  display: flex;
  flex-direction: column;
}

.shipment-totals .shipment-prices {
  align-items: flex-end; /* Aligns prices to the right */
}

.shipment-totals p {
  margin: 0;
}

.shipment-totals .right-price {
  /* margin-left: 15px; */
}

.checkoutclear-btns {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Aligns buttons to the right */
  margin-top: 10px;
}

.checkoutclear-btns {
  display: flex;
  align-items: center; /* Center items vertically */
}

#checkoutBtnLink {
  margin-top: 10px;
}

#clearcartBtn {
  margin-top: 10px;
  margin-left: auto; /* Pushes the button to the far right */
  border-color: green;
  color: green;
  text-transform: none;
}

/* MOST SELLING */

.mostsellingcar-container {
  margin-top: 5%;
}

.container-car {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-car button {
  border: none;
  cursor: pointer;
  color: white;
  background: none;
  transition: all .3s ease-in-out;
}

.container-car #next-btn, 
.container-car #prev-btn {
  border: none;
  border-radius: 50%;
  background-color: green;
  box-shadow: 1px 1px 10px rgb(190, 189, 189);
  cursor: pointer;
  color: white;
  padding: 0px;
  transition: all .3s ease-in-out;
}

.carousel-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  /* padding: 14px 0; */
  transition: all 0.25s ease-in;
}

.carousel-view .item-list {
  max-width: 1000px;
  width: 70vw;
  padding: 30px 0px;
  display: flex;
  gap: 38px;
  scroll-behavior: smooth;
  transition: all 0.25s ease-in;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: auto;
  scroll-snap-type: x mandatory;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.item-list::-webkit-scrollbar {
  display: none;
}

.prev-btn {
  background: none;
  cursor: pointer;
}

.next-btn {
  cursor: pointer;
}

/* FOR SMALL MOBILE */
@media (max-width: 375px) {
  .bigcart-container {
    margin: 0% !important;
  }
  .cart-container {
    border: 0;
  }
  .cartproduct-row {
    justify-content: flex-start; /* Ensure elements are aligned to the start */
  }
  .cartproduct-image {
    flex: 0 0 20%;
  }
  .cartproduct-name {
    flex: 0 0 60%; /* Adjust width to stay on the same line as the image */
    margin-left: 10px;
  }
  .cartpurchase-info {
    flex: 0 0 100%; /* Take full width */
    justify-content: flex-end; /* Align to the end */
    margin-top: 10px; /* Add some margin for spacing */
  }
  .mostsellingcar-container {
    margin-top: 10%;
  }
  .mostselling-h2 {
    margin-left: 1%;
  }
  .shipment-totals .shipment-fees,
  .shipment-totals .shipment-prices {
    width: 100%;
  }
}

/* FOR MEDIUM TO LARGE MOBILE */
@media only screen and (min-width: 376px) and (max-width: 767px) {
  .bigcart-container {
    margin: 0% !important;
  }
  .cart-container {
    border: 0;
  }
  .cartproduct-row {
    justify-content: flex-start; /* Ensure elements are aligned to the start */
  }
  .cartproduct-image {
    flex: 0 0 20%;
  }
  .cartproduct-name {
    flex: 0 0 60%; /* Adjust width to stay on the same line as the image */
    margin-left: 10px;
  }
  .cartpurchase-info {
    flex: 0 0 100%; /* Take full width */
    justify-content: flex-end; /* Align to the end */
    margin-top: 10px; /* Add some margin for spacing */
  }
  .mostsellingcar-container {
    margin-top: 10%;
  }
  .mostselling-h2 {
    margin-left: 1%;
  }
  .shipment-totals .shipment-fees,
  .shipment-totals .shipment-prices {
    width: 100%;
  }
}

/* SMALL TABLETS */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .bigcart-container {
    margin: 0 10% !important;
  }
}

/* BIGGER TABLETS (and small laptops) */
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .bigcart-container {
    margin: 0 10% !important;
  }
}