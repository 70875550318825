#footer-muibox {
    min-height: 50vh !important;
}

.brandpage-container {
    margin-left: 15%;
    margin-right: 15%;
    position: relative;
}

.side-bar {
    position: absolute;
    background-color: white;
    border: 1px solid rgb(214, 209, 209);
    box-shadow: 10px 10px 15px rgba(185, 184, 184, 0.3);
    z-index: 10;
    min-height: 100%;
    width: 100%; /* Expanded width */
    max-width: 100%;
    padding: 15px;
    transition: max-width 0.3s ease, transform 0.3s ease;
    overflow: hidden;
    transform: translateX(0); /* Sidebar fully visible */
}

.brandpage-container .side-bar button {
    border: 0.1px solid #12b034;
    background-color: #62dd7d;
    border-radius: 8px;
    margin-left: auto;
}

.brandpage-container .side-bar li a,p {
    text-decoration: none;
    color: black;
    font-size: 17px;
}

.brandpage-container .collapsed {
    max-width: 0; /* Shrinks to 0 width */
    padding: 0; /* Remove padding for smooth collapse */
    transform: translateX(-100%); /* Slide out of view */
    border: 0;
}

.big-brand-container {
    position: relative;
    margin-bottom: 75px;
    display: flex;
    align-items: flex-start; /* Align items at the start to prevent stretching */
}

.brandpage-container .bigcontainer-opacity {
    opacity: 1 !important;
}

.mobile-brandmenu {
    display: none;
    margin-bottom: 10%;
    margin-top: 7%;
}

.mobile-brandmenu button {
    border: 0.1px solid #12b034;
    background-color: #62dd7d;
    position: absolute;
    border-radius: 8px;
    /* left: 5%; */
}

.mobile-brandmenu button:hover,
.brandpage-container .side-bar button:hover {
    background-color: yellow;
}

#category-h2 {
    margin-bottom: 0;
    margin: 0 auto;
    color: #0d9d2c;
}

.big-brand-container #listAside {
    border: 0.2px solid rgb(215, 213, 213);
    border-radius: 10px;
    width: 300px;
    max-width: 300px;
    padding: 10px;
    flex-shrink: 0; /* Prevent listAside from shrinking */
    align-self: flex-start; /* Align listAside at the start */
}

.big-brand-container #listAside li {
    margin-bottom: 5px;
}

.big-brand-container #listAside li a,p {
    text-decoration: none;
    color: black;
}

.big-brand-container #listAside li a:hover {
    color: lightblue;
}

.big-brand-container #productsAside {
    flex: 1; /* Adjust this to take remaining space while listAside takes its own height */
    margin-left: 20px;
}

.big-brand-container #productsAside:dir(rtl) {
    margin-left: 0;
    margin-right: 20px;
}

.brand-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 0px !important;
}

#brand-padination {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

/* MOBILE */
@media (max-width: 767px) {
    .brandpage-container {
        /* width: 100% !important; */
        margin-left: 2.5%;
        margin-right: 2.5%;
    }
    .side-bar {
        width: 100%;
    }
    .mobile-brandmenu {
        display: flex !important;
    }
    .big-brand-container{
        grid-template-columns: 1fr;
    }
    .brandpage-container .bigcontainer-opacity {
        opacity: 0.5 !important;
    }
    .big-brand-container #listAside {
        display: none;
    }
    .big-brand-container #productsAside {
        width: 100% !important;
        margin: 0 !important;
    }
    .brand-container {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 0px;
        width: 100%;
        overflow-y: hidden;
        max-height: 100%;
    }
}

/* SMALL TABLETS */
@media only screen and (min-width: 768px) and (max-width: 1023px){
    #footer-muibox {
        min-height: 70vh !important;
    }
    .big-brand-container #listAside {
        width: 200px;
        max-width: 200px;
    }
    .big-brand-container #listAside {
        display: block;
    }
    .brandpage-container {
        margin-left: 1%;
        margin-right: 1%;
    }
    .brand-container {
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 5px;
        overflow-y: hidden;
        max-height: 100%;
    }
}

/* BIGGER TABLETS (and small laptops)*/
@media only screen and (min-width: 1024px) and (max-width: 1439px){
    #footer-muibox {
        min-height: 65vh !important;
    }
    .big-brand-container #listAside {
        width: 240px;
        max-width: 240px;
    }
    .brandpage-container {
        margin-left: 2.5%;
        margin-right: 2.5%;
    }
    .brand-container {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 0px;
        overflow-y: hidden;
        max-height: 100%;
    }
}