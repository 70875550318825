/* the whole footer <Box component="footer"> */
footer {
  /* absolute in relative to body and root */
  position: absolute;
  top: 100%;
  background-color: #153060;
  padding: 20px 10px;
  width: 100%;
  flex-shrink: 0; /* Ensure footer doesn't grow or shrink */
}

#footer-muibox {
  flex: 1; /* Push footer to the bottom by taking up remaining space */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* content inside the footer */
footer #footer-container {
  display: flex;
  justify-content: space-between;
  margin: 0 15%;
}

footer #footer-container #box p {
  font-size: 14px;
}

footer p {
  color: white;
  margin-bottom: 0 !important;
}

footer #social-media {
  font-size: 20px;
  margin-top: 15px;
  color: white !important;
}

footer .accepted-card {
  height: 30px;
  width: 60px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  footer #footer-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin: 0;
  }

  #footer #logo {
    width: 30px;
    height: 30px;
  }

  footer #footer-container #box {
    margin: 5px 0 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  footer #footer-container {
    margin: 0;
    gap: 5px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  footer #footer-container {
    margin: 0 0%;
    gap: 5px;
  }
}
