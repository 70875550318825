#signin-bigcontainer {
    margin-top: 5%;
}

#forgotpass-link:hover {
    cursor: pointer;
}

@media (max-width: 767px) {
    .signin-container {
        border: 0 !important;
    }
}