.home-div section {
    margin-top: 3%;
    margin-bottom: 5%;
}

#productshome-h2,
#categorieshome-h2,
#brandshome-h2,
#discountproductshome-h2 {
    margin: 0 !important;
    font-size: 25px;
}

#carousel-bigcontainer {
    margin-left: 13%;
    margin-right: 13%;
    /* display: flex; */
}

.container-car {
    width: 100%;
    display: flex;
    overflow-y: hidden;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 5%;
}

.header-container button {
    background-color: #00cd7c;
    border: none;
    color: white;
    padding: 5px 15px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
}

/* .container-car #next-btn, 
.container-car #prev-btn {
    border: none;
    box-shadow: 1px 1px 10px rgb(190, 189, 189);
    cursor: pointer;
    color: white;
    padding: 0px;
    transition: all .3s ease-in-out;
} */

.item-list {
    width: 100% !important;
    /* padding: 30px 0px; */
    display: flex;
    /* gap: 38px; */
    scroll-behavior: smooth;
    transition: all 0.25s ease-in;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: auto;
    scroll-snap-type: x mandatory;
    overflow-y: hidden;
}

.item-list .item-div {
    margin: 0 20px 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.item-list::-webkit-scrollbar {
  display: none;
}

.prev-btn {
  cursor: pointer;
}

.next-btn {
  cursor: pointer;
}

#categories-container {
    margin-left: 13%;
    margin-right: 13%;
    margin-bottom: 50px;
}

#categories-container #categories-boxes{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
}

#brands-container {
    margin-left: 13%;
    margin-right: 13%;
}

#brands-container #brands-boxes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
}

footer {
    margin-top: 2%;
}

/* FOR MOBILE */
@media (max-width: 767px) {
    .home-div section {
        margin-top: 10% !important;
        margin-bottom: 10% !important;
    }
    .item-list {
        /* width: 67.5vw; */
        /* padding: 15px 0px !important; */
        gap: 20px !important;
    }
    .item-list .item-div {
        margin: 0 5px 0 !important;
    }
    #carousel-bigcontainer {
        margin-left: 10px;
        margin-right: 0;
    }
    #right-arrow, #left-arrow {
        display: none;
    }
    #categories-container {
        margin: 0 15px 20px;
    }
    #categories-container #categories-boxes{
        grid-template-columns: 1fr 1fr;
    }
    #brands-container {
        margin: 0 15px 20px;
    }
    #brands-container #brands-boxes {
        grid-template-columns: 1fr 1fr;
    }
    #categories-container #home-category,
    #categories-container #brand {
        height: 220px;
        margin-bottom: 10px;
    }
    #categories-container #home-category h2 {
        margin-top: 0;
    }
}

/* SMALL TABLETS */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .home-div section {
        margin-top: 6% !important;
        margin-bottom: 8% !important;
    }
    #carousel-bigcontainer {
        margin: 0 5% 0;
    }
    .item-list .item-div {
        margin: 0 15px 0 !important;
    }
    #categories-container {
        margin: 0 5% 0;
        margin-bottom: 50px;
    }
    #categories-container #categories-boxes{
        grid-template-columns: 1fr 1fr 1fr;
    }
    #brands-container {
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 50px;
        grid-template-columns: 1fr 1fr 1fr;
    }
    #brands-container #brands-boxes {
        grid-template-columns: 1fr 1fr 1fr;
    }    
}

/* BIGGER TABLETS (and small laptops)*/
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
    #carousel-bigcontainer {
        margin-left: 2%;
        margin-right: 2%;
    }
    .item-list {
        width: 100% !important;
        padding: 15px 0px !important;
        gap: 20px !important;
    }
    .item-list .item-div {
        margin: 0 10px 0;
    }    
    #categories-container {
        margin-left: 2%;
        margin-right: 2%;
    }
    #categories-container #categories-boxes{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    #brands-container {
        margin-left: 2%;
        margin-right: 2%;
    }
}

@keyframes moveProducts {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-100%);
    }
    50% {
      transform: translateX(-200%);
    }
    75% {
      transform: translateX(-300%);
    }
    100% {
      transform: translateX(-400%);
    }
  }