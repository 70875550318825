#table {
    margin: 0 10%;
    margin-bottom: 390px;
}

#table .cart-th {
    font-size: 25px;
    margin-left: 10px !important;
}

#table td {
    padding: 15px;
}

#table .productImg {
    width: 80px;
    height: 80px;
}

#table .countInput {
    border: 0;
    width: 25px;
}

@media (max-width: 768px) {
    #table {
        max-width: 100%;
        margin: 0 auto;
    }
    #table .cart-th {
        font-size: 20px;
    }
    #table td {
        padding: 5px;
    }
    #table .productName {
        font-size: 16px;
    }
    #table .productImg {
        width: 50px;
        height: 50px;
    }
    #table .removeProductBtn{
        padding: 3;
        /* font */
    }
    #table .checkoutBtn {
        padding: 3px;
    }
}