.wishlist-container {
    border: 1px solid rgb(225, 225, 225);
    border-radius: 10px;
    padding: 10px;
    margin: 0 20% !important;
    margin-top: 2.5% !important;
  }
  
  .wishlistproduct-row {
    padding: 10px;
    border-bottom: 1px solid rgb(225, 224, 224);
    display: flex;
    align-items: center;
    flex-wrap: wrap; /* Enable wrapping for flex items */
  }
  
  .wishlistproduct-image {
    flex: 0 0 10%;
  }
  
  .wishlistproduct-image img {
    height: 100px;
    width: 100px;
  }
  
  .wishlistproduct-name {
    flex: 0 0 60%;
    overflow: hidden;
    padding: 0 10px;
    margin-left: 10px;
  }
  
  .wishlistproduct-name a {
    text-decoration: none;
    color: inherit;
  }
  
  .wishlistproduct-name .product-brand {
    font-weight: bold;
  }
  
  .wishlistproduct-name h6 {
    margin: 5px 0;
    white-space: normal; /* Ensure text wraps */
    word-wrap: break-word; /* Break long words if needed */
  }
  
  .wishlistpurchase-info {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Align to the end by default */
  }
  
  .wishlistpurchase-info input {
    width: 28px;
    text-align: center;
    margin: 0 5px;
    border: 1px solid rgb(212, 211, 211);
  }
  
  .wishlistpurchase-info .MuiIconButton-root {
    padding: 5px; /* Adjust padding for buttons if needed */
  }
  
  /* FOR SMALL MOBILE */
  @media (max-width: 375px) {
    .wishlist-container {
      margin: 0% !important;
      border: 0;
    }
  
    .wishlistproduct-row {
      justify-content: flex-start; /* Ensure elements are aligned to the start */
    }
  
    .wishlistproduct-image {
      flex: 0 0 20%;
    }
  
    .wishlistproduct-name {
      flex: 0 0 60%; /* Adjust width to stay on the same line as the image */
      margin-left: 10px;
    }
  
    .wishlistpurchase-info {
      flex: 0 0 100%; /* Take full width */
      justify-content: flex-end; /* Align to the end */
      margin-top: 10px; /* Add some margin for spacing */
    }
  }
  
  /* FOR MEDIUM TO LARGE MOBILE */
  @media only screen and (min-width: 376px) and (max-width: 767px) {
    .wishlist-container {
      margin: 0% !important;
      border: 0;
    }
  
    .wishlistproduct-row {
      justify-content: flex-start; /* Ensure elements are aligned to the start */
    }
  
    .wishlistproduct-image {
      flex: 0 0 20%;
    }
  
    .wishlistproduct-name {
      flex: 0 0 60%; /* Adjust width to stay on the same line as the image */
      margin-left: 10px;
    }
  
    .wishlistpurchase-info {
      flex: 0 0 100%; /* Take full width */
      justify-content: flex-end; /* Align to the end */
      margin-top: 10px; /* Add some margin for spacing */
    }
  }
  
  /* SMALL TABLETS */
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .wishlist-container {
      margin: 0 5% !important;
    }
  }
  
  /* BIGGER TABLETS (and small laptops) */
  @media only screen and (min-width: 1024px) and (max-width: 1439px) {
    #footer-muibox {
      min-height: 70vh !important;
    }
    .wishlist-container {
      margin: 0 10% !important;
    }
  }