.product {
    width: 200px;
    font-family: "CodecFont1", sans-serif !important;
    padding: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* transition: 0.25s; */
}

.img-box {
    padding-left: 25px;
    padding-right: 25px;
    position: relative;
}

.img-box .product-img {
    scroll-snap-align: center;
    width: 100%;
    height: auto;
    object-fit: cover; /* Ensure images don't stretch */
}

.item_desc {
    margin-top: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.add_cart_btn {
    border: none;
    cursor: pointer;
    background: none;
    color: white;
    transition: all .3s ease-in-out;
    background-color: green;
    border-radius: 10px;
    font-weight: 100;
    font-size: 13px;
    padding: 6px;
    text-align: center;
}

.add_cart_btn:hover {
    background-color: yellow;
    border-color: yellow;
    color: black;
    transition: 0s;
}

.product:hover {
    border: 0.2px solid rgb(229, 228, 228);
    border-radius: 10px;
    transition: 0.25s;
    transform: scale(1.02);
    box-shadow: 10px 10px 15px rgba(185, 184, 184, 0.3);
}

.product_name_div {
    overflow: hidden;
    color: black;
    font-weight: 100;
    margin-bottom: 10px;
    /* line-height: 24px; */
}

.product_name_div p {
    word-wrap: break-word; /* Forces long words to break and wrap to the next line */
    white-space: normal; /* Ensures that text wraps normally */
}

.btn_price_div {
    margin-top: auto;
}

.original-price {
    text-decoration: line-through;
    color: red;
    margin-right: 5px;
}

.discounted-price {
    font-weight: bold;
    color: #404040;
    font-size: 18px;
}

.addcart_btn_div {
    display: flex;
    justify-content: center;
}

.addcart_btn_div .add_cart_btn {
    width: 100%;
    background-color: #00cd7c;
    border: 1px solid #00cd7c;
    margin-top: 10px;
}

@media (max-width: 767px) {
    .product {
        width: 100%;
    }
    .product_name_div {
        /* height: 65px; */
    }
    .item_desc {
        margin-top: 5px !important;
    }
    .item_desc .product_name_div {
        margin-left: 5%;
    }
    .btn_price_div {
        margin-left: 5%;
        margin-right: 5%;
    }
    .btn_price_div span {
        font-size: 16px;
    }
}

@media (max-width: 767px) {
    .product {
        width: 100%;
        margin-bottom: 10px;
    }
    .product_name_div {
        /* height: 65px; */
    }
    .item_desc {
        margin-top: 5px !important;
    }
    .item_desc .product_name_div {
        margin-left: 5%;
    }
    .btn_price_div {
        margin-left: 5%;
        margin-right: 5%;
    }
    .btn_price_div span {
        font-size: 16px;
    }
}

/* SMALL TABLETS */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .product {
        width: 100%;
    }
    .add_cart_btn {
        width: 90% !important;
    }
}

/* BIGGER TABLETS (and small laptops) */
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
    .product {
        width: 100%;
    }
    .add_cart_btn {
        width: 90% !important;
    }
}
