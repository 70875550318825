.test-nav {
    display: flex;
    background-color: black;
    padding: 1px 15px 1px;
    width: 100%;
}

.test-nav .left-side,
.test-nav .middle-side,
.test-nav .right-side {
    margin: 0 10px 0;
}

/* LEFT */

.test-nav .left-side .amazon-div {
    display: flex;
    align-items: center;
    max-width: 125px;
    max-height: 125px;
}

.test-nav .left-side .amazon-div:hover {
    border: 0.1px solid white;
    cursor: pointer;
}


.test-nav .left-side .amazon-div img {
    object-fit: contain;
    max-width: 100px;
    max-height: 100px;
}

.test-nav .left-side {
    display: flex;
    align-items: center;
    /* flex: 1; */
}

.test-nav .left-side .delivery-div {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.test-nav .left-side .delivery-div:hover {
    border: 0.1px solid white;
    cursor: pointer;
}

.test-nav .left-side .delivery-div span {
    color: white;
}

/* MIDDLE */

.test-nav .middle-side {
    display: flex;
    align-items: center;
    /* width: 70%; */
    flex: 1;
    margin: 0 1% 0;
}

.test-nav .middle-side .middle-select {
    font-size: 13px;
    text-align: center;
    height: 77%;
    width: 5%;
    background-color: rgb(230, 224, 224);
    color: black;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.test-nav .middle-side .middle-select:hover {
    cursor: pointer;
}

.test-nav .middle-side .middle-select option {
    background-color: white;
    text-align: left;
}

.test-nav .middle-side input {
    height: 75%;
    border: 0px;
    width: 90%;
    padding-left: 10px;
}

.test-nav .middle-side button {
    font-size: 13px;
    text-align: center;
    height: 76%;
    width: 5%;
    background-color: orange;
    color: black;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 0;
}

/* RIGHT */

.test-nav .right-side {
    display: flex;
    align-items: center;
}

.test-nav .right-side .right-select-div{
    /* width: 90%; */
    display: flex;
    align-items: center;
    background-color: black;
    color: white;
    height: 100%;
}

.test-nav .right-side .right-select-div:hover{
    border: 0.1px solid white;
    cursor: pointer;
}

.test-nav .right-side .right-select-div .right-select{
    background-color: black;
    color: white;
    border: 0;
    cursor: pointer;
}

.test-nav .right-side .signin-div {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 0 8px 0;
}

.test-nav .right-side .signin-div:hover,
.test-nav .right-side .returns-div:hover,
.test-nav .right-side .cart-div:hover{
    border: 0.1px solid white;
    cursor: pointer;
}

.test-nav .right-side .signin-div span {
    color: white;
}

.test-nav .right-side .returns-div,
.test-nav .right-side .cart-div {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 0 8px 0;
}

.test-nav .right-side .returns-div span,
.test-nav .right-side .cart-div span {
    color: white;
}

/* MOBILE INPUT */

.mobile-nav {
    display: none;
}

@media (max-width: 768px) {
    .mobile-nav {
        height: 7vh;
    }
}

@media (max-width: 1366px) {
    .test-nav {
        padding-top: 10px;
    }
    .test-nav .left-side .amazon-div {
        max-width: 100px;
        max-height: 100px;
    }
    .test-nav .middle-side {
       display: none;
    }
    .test-nav .right-side {
        margin-left: auto;
    }
    .test-nav .left-side .delivery-div,
    .test-nav .right-side .right-select-div,
    .test-nav .right-side .returns-div{
        display: none;
    }
    .mobile-nav {
        display: flex;
        background-color: black;
        padding: 10px 10px 10px;
        height: 7vh;
    }
    .mobile-nav .middle-side {
        width: 100%;
        height: 100%;
    }
    .mobile-nav .middle-side input {
        height: 100%;
        border: 0px;
        width: 90%;
        padding-left: 10px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    .mobile-nav .middle-side button {
        font-size: 13px;
        text-align: center;
        height: 100%;
        width: 10%;
        background-color: orange;
        color: black;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border: 0;
    }
}
  
/* SMALL TABLETS */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    /* .mobile-nav {
        display: flex;
        background-color: black;
        padding: 10px 10px 10px;
        height: 6vh;
    } */
}
  
/* BIGGER TABLETS (and small laptops) */
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
    
}