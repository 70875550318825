.product-container {
    display: flex;
    margin-left: 15% !important;
    margin-right: 15% !important;
    margin-top: 5%;
}

.left-child {
    display: flex;
    flex: 1;
}

.left-child .smallproduct-images {
    flex-direction: column;
}

.left-child .smallproduct-images .smallproduct-image,
.smallproductmobile-image {
    margin-bottom: 10px;
    border: 1px solid black;
    border-radius: 10px;
    height: 80px;
    width: 80px;
}

.left-child .smallproduct-images .smallproduct-image:hover {
    cursor: pointer;
}

.left-child .smallproduct-images .smallproduct-image.selected,
.smallproductmobile-image.selected {
    border: 1.4px solid rgb(35, 197, 35);
}

.left-child #bigproduct-image {
    background-color: white;
    padding: 10px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    object-fit: cover;
    border: 0px !important;
}

.left-child #bigproduct-image img {
    height: 100%;
    width: 100%;
}

.left-child .smallproductmobile-images{
    display: none;
}

.left-child #productCarousel {
    width: 100%;
}

.carousel-control-next,
.carousel-control-prev {
    filter: invert(100%);
}

.left-child #delete-product {
    background-color: white;
    padding-bottom: 10px;
    padding-top: 10px;
}

.left-child #editproduct-btn:dir(rtl){
    margin-left: 0 !important;
    margin-right: 15px !important;
}

/* RIGHT CHILD */
.right-child {
    padding: 10px;
    flex: 1;
    flex-direction: column;
}

.right-child #top-panel {
    width: 100%;
}

.right-child #middle-panel {
    margin-top: 15px;
    border: 1px solid rgb(227, 225, 225);
    border-radius: 8px;
    padding: 15px;
    padding-top: 0;
    padding-bottom: 0;
}

.right-child #middle-panel .middlepanel-section {
    display: flex;
    padding: 15px;
    padding-left: 0;
}

.right-child #middle-panel .productmiddle-right {
    margin-left: auto;
    font-weight: 600;
}

#cartwish-buttons {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.cart-button,
.wishlist-button {
    border-radius: 0px !important;
    width: 95% !important;
    height: 100%;
    cursor: pointer;
}

.right-child #middle-panel td {
    padding: 10px !important;
}

.product-overview {
    background-color: #f9f9f9;
    padding-top: 30px;
}

.product-overview .bottom-panel {
    padding-bottom: 10px;
}

.product-overview .table td {
    background-color: #e9f2ff;
}

/* FOR MOBILE */
@media (max-width: 767px) {
    .product-container {
        flex-direction: column;
        align-items: center;
        display: flex;
        margin: 0 !important;
    }
    .left-child,
    .right-child {
        width: 100%;
        margin: 0 !important;
    }
    .left-child {
        flex-direction: column;
    }
    .left-child .smallproduct-images {
       display: none;
    }
    .left-child .smallproductmobile-images{
        display: flex;
        /* justify-content: space-evenly; */
        flex-direction: row;
        flex-wrap: wrap;
    }
    .left-child .smallproductmobile-image {
        margin-left: 10px;
    }
    .right-child {
        margin-top: 20px;
    }
    .right-child #top-panel h1 {
        font-size: 21px !important;
    }
    .left-child #top-panel h1 {
        display: inline;
    }
    .cart-button,
    .wishlist-button {
        float: right !important;
        font-size: 12px !important;
        padding: 5px !important;
    }
    td {
        padding: 10px !important;
    }
    .product-overview {
        margin-top: 5%;
    }
    .product-overview .container {
        margin: 0 !important;
    }
    .product-overview .table {
        width: 100% !important;
    }
}

/* SMALL TABLETS */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .product-container {
       margin: 2% !important;
    }
}

/* BIGGER TABLETS (and small laptops)*/
@media only screen and (min-width: 1024px) and (max-width: 1439px){
    .product-container {
        margin-left: 8% !important;
        margin-right: 8% !important;
    }
    .product-overview {
        margin-top: 5%;
    }
}